export const Home_Rg1 = () => {
  return (
    <>
      <div className="my-div">
        <div className="col-12 p-4">
            <div className="col d-flex flex-column position-static">
              <h2 className="d-inline-block text-primary-emphasis">Pharmabroker</h2>
              <h2 className="mb-0 text-primary-emphasis mb-2">inserzioni per comprare o vendere una farmacia</h2>
              <div className="my-div text-secondary-emphasis mb-4">Se vuoi comprare una farmacia, se vuoi vendere una farmacia,
                se vuoi una valutazione della tua farmacia,<br></br> contattaci</div>
                <div className="d-grid gap-2 d-md-flex justify-content-center">


                <button type="button" className="btn btn-warning btn-lg">
                  <a className='btn-href my-text-dark' href={"/Inserzione/"}>Fai una nuova inserzione</a>
                </button>



                <button type="button" className="btn btn-warning btn-lg">
                <a className='btn-href my-text-dark' href={"/Inserzione/"}>Chiedi una valutazione</a>
                </button>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Home_Rg1;