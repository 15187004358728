import React, { Component, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

// import { v4 as uuid } from "uuid";

export const Regioni_Sb = () => {
  const [DatiJson, setUsers] = useState([])
  const [DatiTipo, setTip] = useState([])
  const [DatiTotale, setTot] = useState([])

  const [cookies, setCookie] = useCookies(['scroll']);

  const fetchTotData = () => {
    fetch("https://org.farmacontatto.it/PhsCrm/ricerche/esegui/get_ricerche_web.php?p=Tot")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setTot(data)
      })
  }
  useEffect(() => {
    fetchTotData()
  }, [])

  const fetchUserData = () => {
    fetch("https://org.farmacontatto.it/PhsCrm/ricerche/esegui/get_ricerche_web.php?p=Reg")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
      })
  }
  useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <>
      <div>
        <h4 className="fst-italic pt-2">Inserzioni Farmacie
        </h4>
        <div className="row flex-md-row pt-1">
          {DatiJson.length > 0 && (
            <> 
              <div className="col-md-12">
                {DatiTotale.map(ftot => (
                  <div className="row mb-1" key={ftot.id} >
                    <div className="col-md-12 text-center">

                      <a href={"/"}><b>Totale inserzioni ({ftot.totale})</b>
                      </a>
                    </div>
                    <div className="col-md-12 text-center">
                      <a href={"/InserzioniFarmacie/vendita"}><b style={{ backgroundColor: '#54B4D3', color: '#FFFFFF', padding: '0px 10px 0px 10px' }}>Farmacie in Vendita ({ftot.vendite})</b>
                      </a>
                    </div>

                    <div className="col-md-12 text-center">
                      <a href={"/RicercaFarmacie/ricerca"}><b style={{ backgroundColor: '#8fc963', color: '#FFFFFF', padding: '0px 10px 0px 10px' }}>Ricerca Farmacie ({ftot.ricerche})</b>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <h4 className="fst-italic pt-2">Per Regione</h4>
              <div className="col-md-12" >
                {DatiJson.map(farma => (
                  <div className="row mb-1" key={farma.id}>
                    <div className="col-md-12 text-center">
                      <a href={"/InserzioniFarmacie/" + farma.regione}><b>{farma.regione} ({farma.numero})</b>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="col-12" id='Comprare una farmacia'>
          </div>
        </div>
        
      </div>
    </>
  )
}
export default Regioni_Sb;