
import Button from 'react-bootstrap/Button';
import {useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
import ContattiBottone from './ContattiBottone';

export const NonDisponibile = () => {

    document.title = 'La farmacia ricercata non è più disponibile';
    const navigate = useNavigate();

    return (
        <div className='py-5'>
            <h1>Impossibile trovare la pagina</h1>
            <h3 className="px-5 pb-2">Ci dispiace, la farmacia ricercata non è più disponibile
            oppure l'indirizzo che stai digitando è errato.</h3>
            <h1>Prova a cercare altre opportunità</h1>
            <p>Premi per tornare alla pagina precedente:</p>
            <Button variant="warning"
                onClick={() => navigate(-1)}>Ritorna alla pagina precedente
            </Button>
            <p className="pt-3">Premi per tornare alla pagina principale:</p>
            <Link to='/'>
            <Button variant="warning">Torna alla pagina principale</Button>
            </Link>
            <p className='pt-3'>Oppure:</p>
            <ContattiBottone etichetta='Contattaci' />
        </div>
    )
}
export default NonDisponibile;