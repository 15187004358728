
import { Ritorna } from './Ritorna';
import { Pharmascout_Sb } from './Pharmascout_Sb';
import { Regioni_Sb } from './Regioni_Sb';
import { AltrePagine_Sb } from './AltrePagine_Sb';

export const InserzioniFarmacie_Sb = () => {
  return (
    <>
      <div className="col-md-3">
        <div className="position-sticky" style={{ top: '2rem' }}>
          {/* Commento */}
          <Ritorna />
          <Regioni_Sb />
          <Pharmascout_Sb />
          <AltrePagine_Sb />
        </div>
      </div>
    </>
  )
}

export default InserzioniFarmacie_Sb;