import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { RicercaFarmacie_lista }  from './RicercaFarmacie_lista';
import { InserzioniFarmacie_Head } from './InserzioniFarmacie_Head';
import { Home_Rg1 } from './Home_Rg1';

export const RicercaFarmacie = () => {

  return (
    <>
      <Navbar1 />
      <InserzioniFarmacie_Head />
      <Home_Rg1 />
      <RicercaFarmacie_lista />
    </>
  )
}