
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Testa } from './Testa';
import { Testa_md } from './Testa_md';
import { Testa_sm } from './Testa_sm';

// document.title = 'Pharmabroker - inserzioni ricerca farmacie in vendita';

export const Home_Head = () => {
  return (
    <>

      <HelmetProvider>
        <Helmet>
        <title>Inserzioni ricerca farmacie in vendita e annunci farmacie in vendita - Pharmabroker</title>
          <meta name="description" content='▷ Vuoi fare un’inserzione per comprare una farmacia? ▷ Hai una farmacia da vendere? Pharmabroker offre massima riservatezza e visibilità ai tuoi annunci.' />
        </Helmet>
      </HelmetProvider>

      {/* Visibile su schermi extra grandi */}
      <div className="container-fluid d-none d-xl-block" id='Pharmabroker'>
        <Testa />
      </div>

      {/* Visibile su schermi grandi */}
      <div className="container-fluid d-none d-lg-block d-xl-none" id='Pharmabroker'>
        <Testa />
      </div>

      {/* Visibile su schermi medi */}
      <div className="container-fluid d-none d-md-block d-lg-none" id='Pharmabroker'>
        <Testa_md />
      </div>

      {/* Visibile su schermi piccoli */}
      <div className="container-fluid d-none d-sm-block d-md-none" id='Pharmabroker'>
        <Testa_sm />
      </div>

      {/* Visibile su schermi extra piccoli */}
      <div className="container-fluid d-xs-block d-sm-none pt-5" id='Pharmabroker'>
        <Testa_sm />
      </div>
    </>
  )
}

export default Home_Head;