
import Button from 'react-bootstrap/Button';
import {useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";

export const NotFound = () => {

    document.title = '404 Page Not found';
    const navigate = useNavigate();

    return (
        <div>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <br></br><br></br>
            <h1>Oops! Pagina non trovata.</h1>
            <br></br>
            <p>Premi per tormare alla pagina precedente:</p>

            <Button variant="warning"
                onClick={() => navigate(-1)}>Ritorna alla pagina precedente
            </Button>
            <br></br><br></br>
            <p>Oppure premi per tormare alla pagina principale:</p>

            <Link to='/'>
            <Button variant="warning">Torna alla pagina principale</Button>
            </Link>
            <br></br><br></br><br></br>
        </div>
    )
}
export default NotFound;