import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navbar1 } from './navbar1';
import { SingolaInserzione_Sb } from './SingolaInserzione_Sb';
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import envelope_circle_check_solid from './icone/envelope-circle-check-solid.svg';
import ContattiBtnModal from './ContattiBtnModal';
import { RitornaIndietro } from './RitornaIndietro';
import { NonDisponibile } from './NonDisponibile';

export const RicercaFarmacia = () => {

  const colorOptions = { color: '#6082B6' }
  const { url_pagina } = useParams();
  
  // const [searchParams,] = useSearchParams();

  //  let api_url = "https://org.farmacontatto.it/PhsCrm/ricerche/esegui/get_ricerca_web.php?p='" + searchParams.get('p') + "'"
  let api_url = "https://org.farmacontatto.it/PhsCrm/ricerche/esegui/get_ricerca_web.php?p='" + url_pagina + "'"

  const [DatiJson, setFarma] = useState([])
  let [isLoaded, setIsLoaded] = useState(false);
  let [err, setErr] = useState(null);
  let [latitudine, setLat] = useState(null);
  let [seo_description, setSeo] = useState(null);
  let [seo_titolo, setTitolo] = useState(null);

  useEffect(() => {
    const getFarma = () => {
      fetch(api_url)
        .then(response => {
          if (response.status >= 400) {
            throw new Error("Si è verificato un errore")
          }
          return response.json()
        })
        .then(DatiJson => {
          setFarma(DatiJson)
          setIsLoaded(true)
          setLat(DatiJson.map(farma => farma.lat))
          setSeo(DatiJson.map(farma => farma.testo_seo))
          setTitolo(DatiJson.map(farma => farma.titolo_inserzione))
        },
          err => {
            setErr(err)
            setIsLoaded(true)
          })
    };
    getFarma()
  }, [])

  if (err) {
    return <div> {err.message} </div>
  } else if (!isLoaded) {
    return <div> Loading... </div>
  } else {
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>{'Pharmabroker -  ' + seo_titolo}</title>
            <meta name="description" content={seo_description} />
            <link rel="canonical" href={"https://pharmabroker.it/RicercaFarmacia/"  + url_pagina}/>
          </Helmet>
        </HelmetProvider>
        <Navbar1 />
        <div className='my-div mt-5'>
          <div className="row g-2 mt-5">
            <div className="row g-2 mt-4">
              <div className="col-md-9">
                <article className="blog-post p-4"
                  style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
                  <div className="row flex-md-row pt-3">

                    {DatiJson.length > 0 ?
                      <div className="col-md-12">
                        {DatiJson.map(farma => (
                          <div className="row" key={farma.url_pagina}>
                            <h1 className="blog-post-meta">
                              {farma.titolo_inserzione}
                            </h1>

                            <div className="col-md-12">
                              <div className="card flex-md-row mb-4 box-shadow h-md-250">

                                <div className="d-none d-lg-block">

                                  <img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt={farma.title_immagine} title={farma.title_immagine} width="100" height="100" />
                                  <br></br>
                                  {farma.regione}
                                  <br></br>
                                  < RitornaIndietro />
                                </div>

                                <div className="card-body align-items-start">

                                  <div className="container-fluid">
                                    <div className="row justify-content-between">
                                      <div className="col-md-12 col-xl-6">
                                        <div className={"mx-1 my-trattativa-" + farma.colore_stato_trattative}>
                                          {farma.stato_trattative}
                                        </div>
                                      </div>

                                      <div className="col-sm-12 text-center col-xl-4 text-end">
                                        <div className="my-text-dark">
                                          <span>Rif: {farma.rif_ricerca}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}
                                    </h2>
                                    <p className="my-card-text text-start mx-4 ">{farma.testo_inserzione}</p>

                                    <div className="row flex-md-row pt-3">
                                      {latitudine != '' ? (
                                        <>
                                          <div className="col-md-12 col-xl-8">
                                            <MapContainer style={{ height: "100%", minHeight: "100%" }}
                                              center={{ lat: farma.lat, lng: farma.lng }}
                                              zoom={farma.zoom} scrollWheelZoom={false} >
                                              <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                              />
                                              <Circle
                                                center={{ lat: farma.lat, lng: farma.lng }}
                                                pathOptions={colorOptions}
                                                radius={farma.raggio}
                                                stroke={true}
                                                fillOpacity={0.1}
                                              />
                                            </MapContainer>
                                          </div>

                                          <div className="col-md-12 col-xl-4 mt-3">
                                            <img src={envelope_circle_check_solid} alt="Generic placeholder image" width="50" height="50" />

                                            <h2>Vuoi contattare l'inserzionista? </h2>
                                            <p>Contattaci per maggiori informazioni.</p>
                                            <p>
                                            <ContattiBtnModal riffarma={farma.rif_ricerca} titolo= {farma.titolo_inserzione}/>
                                            </p>
                                          </div>

                                        </>
                                      ) : null}

                                      {latitudine == '' ? (
                                        <div className="col-12 mt-3">
                                          <img src={envelope_circle_check_solid} alt="Generic placeholder image" width="50" height="50" />

                                          <h2>Vuoi contattare l'inserzionista? </h2>
                                          <p>Contattaci per maggiori informazioni.</p>
                                          <p>
                                          <ContattiBtnModal riffarma={farma.rif_ricerca} titolo= {farma.titolo_inserzione}/>
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      : <NonDisponibile /> };
                    <div className="col-12" id='Comprare una farmacia'>
                    </div>
                  </div>
                </article>
              </div>
              < SingolaInserzione_Sb />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default RicercaFarmacia;