import React, { useEffect, useState } from "react"
import { Inserzioni_Sb } from './Inserzioni_Sb';
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CookiesProvider, useCookies } from "react-cookie";

export const Inserzioni_Lista = () => {

  const [cookies, setScrollCookie, removeCookie] = useCookies(['scroll']);

  const { regione } = useParams();
  const [searchParams,] = useSearchParams();

  var pagina = "";

  if (regione == "ricerca") {
    pagina = "/RicercaFarmacia";
  } else {
    pagina = "/FarmaciaInVendita";
    pagina = "/RicercaFarmacia";
  }

  let api_url = "https://org.farmacontatto.it/PhsCrm/ricerche/esegui/get_ricerche_web.php"

  const [DatiJson, setFarma] = useState([])
  let [isLoaded, setIsLoaded] = useState(false);
  let [err, setErr] = useState(null);

  // window.scrollTo(0,500);

  // setScrollCookie('scroll', 0, {maxAge:3600})

  // retrive scroll inizio
  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setScrollCookie('scroll', scrollPosition, { maxAge: 3600 })
    console.log(scrollPosition);
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // retrive scroll fine

  useEffect(() => {

    const getFarma = () => {
      fetch(api_url)
        .then(response => {
          if (response.status >= 400) {
            throw new Error("Si è verificato un errore")
          }
          return response.json()
        })
        .then(DatiJson => {
          setFarma(DatiJson)
          setIsLoaded(true)
        },
          err => {
            setErr(err)
            setIsLoaded(true)
          })
    };
    getFarma()
  }, [])


  if (err) {
    return <div> {err.message} </div>
  } else if (!isLoaded) {
    return <div> Loading... </div>
  } else {
    return (

      <>

        <HelmetProvider>
          <Helmet>
          <title>Inserzioni ricerca farmacie in vendita e annunci farmacie in vendita - Pharmabroker</title>
          <meta name="description" content='Vuoi fare un’inserzione per comprare una farmacia? ⭐ Hai una farmacia da vendere? ⭐ Pharmabroker offre massima riservatezza e visibilità ai tuoi annunci.' />
          </Helmet>
        </HelmetProvider>

        <div className='my-div'>
          <div className="row g-2">
            <div className="col-md-9">
              <article className="blog-post p-4"
                style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
                <h1 className="blog-post-meta">
                  Inserzioni per compravedita farmacie
                </h1>
                <h2>qui troverai inserzioni di ricerca farmacie in vendita</h2>
                <div className="row flex-md-row pt-3">
                  {DatiJson.length > 0 ?

                    <div className="col-md-12">
                      {DatiJson.map(farma => (
                        <div className="row mb-2" key={farma.url_pagina}>
                          <div className="col-md-12">
                            {farma.tipo_inserzione == '1' &&

                              <div className="card flex-md-row mb-4 box-shadow h-md-250 ">
                                <div className="d-none d-lg-block">
                                  <a href={'/RicercaFarmacia/' + farma.url_pagina}>
                                    <img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt={farma.title_immagine} title={farma.title_immagine} width="100" height="100" />
                                    <br></br>{farma.regione}</a>
                                </div>
                                <div className="card-body d-flex flex-column align-items-start">

                                  <div className="container">
                                    <div className="row justify-content-between">
                                      <div className={"col-xl-4 col-md-6 col-sm-8 my-trattativa-" + farma.colore_stato_trattative}>{farma.stato_trattative}
                                      </div>
                                      <div className={"col-xl-2 col-md-3 col-sm-4 my-text-dark"}>Rif: {farma.rif_ricerca}
                                      </div>
                                    </div>
                                  </div>
                                  <a href={'/RicercaFarmacia/' + farma.url_pagina}>
                                    <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}
                                    </h2>
                                  </a>
                                  <p className="my-card-text text-start mx-4 ">{farma.riassunto_inserzione}</p>
                                  <button type="button" className="btn btn-warning btn-md">
                                    <a className='btn-href my-text-dark' href={'/FarmaciaInVendita/' + farma.url_pagina}>Scopri di più</a>
                                  </button>
                                </div>
                              </div>
                            }
                            {farma.tipo_inserzione == '2' &&

                              <div className="card flex-md-row mb-4 box-shadow h-md-250 ">
                                <div className="d-none d-lg-block">
                                  <a href={'/RicercaFarmacia/' + farma.url_pagina}>
                                    <img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt={farma.title_immagine} title={farma.title_immagine} width="100" height="100" />
                                    <br></br>{farma.regione}</a>
                                </div>
                                <div className="card-body d-flex flex-column align-items-start">

                                  <div className="container">
                                    <div className="row justify-content-between">
                                      <div className={"col-xl-4 col-md-6 col-sm-8 my-trattativa-" + farma.colore_stato_trattative}>{farma.stato_trattative}
                                      </div>
                                      <div className={"col-xl-2 col-md-3 col-sm-4 my-text-dark"}>Rif: {farma.rif_ricerca}
                                      </div>
                                    </div>
                                  </div>
                                  <a href={'/RicercaFarmacia/' + farma.url_pagina}>
                                    <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}
                                    </h2>
                                  </a>
                                  <p className="my-card-text text-start mx-4 ">{farma.riassunto_inserzione}</p>
                                  <button type="button" className="btn btn-warning btn-md">
                                    <a className='btn-href my-text-dark' href={'/RicercaFarmacia/' + farma.url_pagina}>Scopri di più</a>
                                  </button>
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      ))}
                    </div>
                    : <div> Farmacie non trovate! </div>}
                  <div className="col-12" id='Comprare una farmacia'>
                  </div>
                </div>
              </article>
            </div>
            < Inserzioni_Sb />
          </div >
        </div >
      </>

    );
  }
}

export default Inserzioni_Lista;