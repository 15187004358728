
import logo from './images/logo.png';

import {
    Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link as Links } from 'react-scroll';
import NavDropdown from 'react-bootstrap/NavDropdown';

import ContattiBtnNavbar from './ContattiBtnNavbar';


export const Navbar2 = () => {

    return (
        <>
            <Navbar bg="light" expand="lg" fixed='top'>
                <Container>

                    <ContattiBtnNavbar />

                    <img src={logo} alt="Pharmabroker" width="30" height="30" style={{ margin: 15 }} className="d-inline-block align-text-top"></img>
                    <Navbar.Brand href="/" title="Pharmabroker"> Pharmabroker</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-brand" href="#">

                            <Nav.Link as={Link} to="/">Home</Nav.Link>

                            <NavDropdown title="Privacy & Cookie" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/Condizioni">Condizioni d’uso e Termini del servizio</NavDropdown.Item>
                                <NavDropdown.Item href="/Privacy">Trattamento dei dati personali (Privacy)</NavDropdown.Item>
                                <NavDropdown.Item href="/Cookies">Informazioni Generali sull’Uso dei Cookie</NavDropdown.Item>
                            </NavDropdown>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navbar2;