import React from "react";

import Sfondo_farmacia from './images/Home_Farmacia.png';
import Dottoressa_stretta from './images/Head-Pharmabroker.png';

export const Testa_sm = () => {

    document.title = 'Pharmabroker - inserzioni farmacie';

    return (
        <>
            <div className="mycontainer">
                <div className="text-center border-bottom" id='Compravendita di farmacie'
                    style={{
                        backgroundImage: `url(${Sfondo_farmacia})`,
                        backgroundSize: 'cover'
                    }}>
                    <div className="row g-0 " style={{ backgroundColor: 'rgba(114, 103, 57, 0.6)', color: 'white' }}>

                        <div className="col-md-6" style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            paddingTop: '15vw'
                        }}>
                            <div className="card flex border-0 h-md-200"
                                style={{ backgroundColor: 'rgba(1, 1, 1, 0.0)' }}>
                                <div className="card-body mt-5">
                                    <h1 className="fw-bold mb-4"
                                        style={{
                                            fontSize: '10vw',
                                            WebkitTextStrokeWidth: '1px',
                                            WebkitTextStrokeColor: '#000000'
                                        }}>
                                        <font color="#F5F5F5">Pharma</font><font color="#8fc963">broker</font>
                                    </h1>
                                    <h2 className="mb-4"
                                        style={{
                                            fontSize: '5vw'
                                        }}>
                                        <font color="#F5F5F5">Compravendita farmacie</font>
                                    </h2>
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                <button type="button" className="btn btn-warning btn-lg px-4 mx-5">
                                  <a className='btn-href my-text-dark' href={"/Inserzione/"}>Fai una nuova inserzione</a>
                                </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 float-end ">
                            <img src={Dottoressa_stretta} style={{ width: "100%", color: 'white', backgroundSize: 'cover' }} alt="Compravendita farmacie" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}