import {useNavigate } from 'react-router-dom';

export const RitornaIndietro = () => {
  
  const navigate = useNavigate();

  return (
    <>
          <div className="p-3 bg-white rounded" >
            <button type="button" className="btn btn-warning btn-sm" onClick={() => navigate(-1)}>Ritorna alla lista
            </button>
          </div>
    </>
  )
}
export default RitornaIndietro;