import { Helmet, HelmetProvider } from 'react-helmet-async';
import { InserzioneTesta } from './InserzioneTesta';
import { InserzioneTesta_md } from './InserzioneTesta_md';
import { InserzioneTesta_sm } from './InserzioneTesta_sm';

document.title = 'Pharmabroker - inserzioni ricerca farmacie in vendita';

export const Inserzione_Head = () => {
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Inserimento inserzioni di ricerca farmacie in vendita e annunci di vendita - Pharmabroker</title>
          <meta name="description" content='Pharmabroker.it: il portale online per la compravendita di farmacie. ⭐ Inserzioni verificate, riservatezza e professionalità per aiutarti a realizzare il tuo sogno.' />
        </Helmet>
      </HelmetProvider>

      {/* Visibile su schermi extra grandi */}
      <div className="container-fluid d-none d-xl-block" id='Pharmabroker'>
        <InserzioneTesta />
      </div>

      {/* Visibile su schermi grandi */}
      <div className="container-fluid d-none d-lg-block d-xl-none" id='Pharmabroker'>
        <InserzioneTesta />
      </div>

      {/* Visibile su schermi medi */}
      <div className="container-fluid d-none d-md-block d-lg-none" id='Pharmabroker'>
        <InserzioneTesta_md />
      </div>

      {/* Visibile su schermi piccoli */}
      <div className="container-fluid d-none d-sm-block d-md-none" id='Pharmabroker'>
        <InserzioneTesta_sm />
      </div>

      {/* Visibile su schermi extra piccoli */}
      <div className="container-fluid d-xs-block d-sm-none pt-5" id='Pharmabroker'>
        <InserzioneTesta_sm />
      </div>
    </>
  )
}

export default Inserzione_Head;