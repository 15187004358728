
import Agenzia_Farmacie from './Immagini/Agenzia-Farmacie.png';
import ChkOk from './Immagini/check.png';

export const Chisiamo_Rg3 = () => {
  return (
    <>
      <div className="row">
        <div className="container p-4">

          <div className="row pb-0 p-5 align-items-center"
            style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}
          >
            <div className="row flex-sm-column-reverse flex-md-row">
              <div className="col-md-6 order-sm-2 align-self-center" id='Vendere la tua farmacia'>
                <img src={Agenzia_Farmacie} className="d-block mx-lg-auto img-fluid" alt="Pharmabroker - Inserzioni farmacie in vendita" width="650" loading="lazy" />
              </div>
              <div className="col-md-6 order-sm-1">
                <div className="mb-2 text-primary-emphasis text-start pt-4">
                  <h2 style={{ color: '#f57e20', fontWeight: 'bold' }}>CHI SIAMO E COME LA PENSIAMO</h2>{' '}
                </div>
                <div style={{ fontSize: '1.1em' }}>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                    Sappiamo bene che quello delle farmacie è un mercato esclusivo e particolare.
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                  Siamo intermediari specializzati in farmacie e siamo contenti di collaborare con altre agenzie e seri intermediari specializzati in farmacie e nella loro compravendita.
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                  Sul sito Pharmabroker.it avrai la possibilità di pubblicare il tuo annuncio e consultare le inserzioni di altri farmacisti privati e delle agenzie specializzate nel settore della compravendita delle farmacie che collaborano con noi. Le tue inserzioni potranno essere visibili anche su <a href="https://pharmascout.it" target="_blank"><b>Pharmascout.it.</b></a>
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                    Il servizio ha lo scopo di fornire maggiori possibilità di successo a chi cerca una farmacia da acquistare o a chi vuole vendere una farmacia.
                  </p>
                  <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                  Le inserzioni sono gratuite. Cliccando sui bottoni dedicati e compilando il modulo sarai richiamato nel più breve tempo possibile. Ti illustreremo i dettagli del servizio, concorderemo il testo dell'annuncio e ti assisteremo nel realizzare i tuoi obiettivi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Chisiamo_Rg3;





