import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { InserzioniFarmacie_Head } from './InserzioniFarmacie_Head';
import { FarmacieInVendita_lista }  from './FarmacieInVendita_lista';
import { Home_Rg1 } from './Home_Rg1';

export const FarmacieInVendita = () => {

  return (
    <>
      <Navbar1 />
      <InserzioniFarmacie_Head />
      <Home_Rg1 />
      <FarmacieInVendita_lista />
    </>
  )
}