import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { Home_Head } from './Home_Head';
import { Home_Rg1 } from './Home_Rg1';
import { Inserzioni_Lista }  from './Inserzioni_Lista';

export const Home = () => {

  return (
    <>
    {/* commento */}
    <Home_Head />
    <Navbar1 />
    <Home_Rg1 />
    <Inserzioni_Lista />
    </>
  )
}