
import { InserzioniFarmacieTesta } from './InserzioniFarmacieTesta';
import { InserzioniFarmacieTesta_md } from './InserzioniFarmacieTesta_md';
import { InserzioniFarmacieTesta_sm } from './InserzioniFarmacieTesta_sm';

document.title = 'Pharmabroker - inserzioni ricerca farmacie in vendita';

export const InserzioniFarmacie_Head = () => {
  return (
    <>
          {/* Visibile su schermi extra grandi */}
          <div className="container-fluid d-none d-xl-block" id='Pharmabroker'>
            <InserzioniFarmacieTesta />
          </div>

          {/* Visibile su schermi grandi */}
          <div className="container-fluid d-none d-lg-block d-xl-none" id='Pharmabroker'>
            <InserzioniFarmacieTesta />
          </div>

          {/* Visibile su schermi medi */}
          <div className="container-fluid d-none d-md-block d-lg-none" id='Pharmabroker'>
            <InserzioniFarmacieTesta_md />
          </div>

          {/* Visibile su schermi piccoli */}
          <div className="container-fluid d-none d-sm-block d-md-none" id='Pharmabroker'>
            <InserzioniFarmacieTesta_sm/>
          </div>

          {/* Visibile su schermi extra piccoli */}
          <div className="container-fluid d-xs-block d-sm-none pt-5" id='Pharmabroker'>
            <InserzioniFarmacieTesta_sm />
          </div>
    </>
  )
}

export default InserzioniFarmacie_Head;