import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { Inserzione_Head } from './Inserzione_Head';
import { Inserzione_moduli }  from './Inserzione_moduli';
import { Home_Rg4 } from './Home_Rg4';

export const Inserzione = () => {

  return (
    <>
      <Navbar1 />
      {/*
      <Inserzione_Rg1 />
      <Home_Rg4 />
    */}
    <Inserzione_Head />
    <Inserzione_moduli />
    </>
  )
}