import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  // aggiorna costantemente la posizione dello scroll
  const [cookies, setCookie] = useCookies(['scroll']);
  const [cookies1, setRegCookie] = useCookies(['newregione']);

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    // Ricavo la regione e la metto in paramregione
    const str = pathname.toString();
    const paramregione = str.split('/').pop();

    if (pathname == "/chisiamo" || pathname == "/inserzione") {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      setRegCookie('newregione', '', { maxAge: 3600 })
    }

    window.onload = function () {
      //  alert(cookies1.newregione + " - " + paramregione + ' - ' + cookies.scroll + ' - ' + pathname );
      // Confronto la regione con la regione precedentemente scritta nel cookie
      // Se sono diverse azzero lo scroll per partire dalla cima dell'elenco inserzioni.
      if (cookies1.newregione != paramregione && pathname != '/RicercaFarmacia' && pathname != '/FarmaciaInVendita') {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
        setRegCookie('newregione', paramregione, { maxAge: 3600 })
      } else {
        if (pathname == '/RicercaFarmacie/ricerca' ||
          pathname == '/InserzioniFarmacie/vendita' ||
          pathname == '/' ||
          pathname == '/InserzioniFarmacie/' + paramregione) {
          setTimeout(() => {
            window.scrollTo(0, cookies.scroll);
          }, 100);
        } else {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      }
    }

  }, [pathname]);
}

export default ScrollToTop;