import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from "react";
import { Navbar2 } from './navbar2';

export const Condizioni = () => {

    return (
        <>
            <HelmetProvider>
                <Helmet>
                <meta name="robots" content="noindex"></meta>
                </Helmet>
            </HelmetProvider>

            <Navbar2 />
            <div style={{ marginTop: '100px' }}>

                <div className="container-fluid pt-5 g-0" style={{
                    width: "80%",
                    marginTopTop: 80,
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    paddingTop: 60,
                    paddingBottom: 30,
                    border: "3px solid lightGray",
                }}>
                    <h1>Condizioni d’Uso e Termini del Servizio</h1>
                </div>

                <div className="container-fluid pt-3 g-0" style={{
                    width: "80%",
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    paddingTop: 30,
                    paddingBottom: 30,
                    border: "3px solid lightGray",
                    textAlign: "justify",
                }}>
                    Il sito web Pharmabroker all’indirizzo Pharmabroker.it (di seguito: “Sito”) é di proprietà della società Farma Contatto S.a.s. di Achille Bonzini &amp; C. con sede legale in Milano, Via Giambellino, n. 119 (P. IVA 09550310966) R.E.A. MI-2098123 (di seguito: “Farmacontatto”).
                    <blockquote><span style={{ color: '#4d648d' }}><strong>L'ingresso nel Sito e l’uso delle informazioni e servizi in esso contenuti comportano il rispetto e l'accettazione di tutte le presenti Condizioni d’Uso</strong></span> (di seguito: “Condizioni”).</blockquote>

                    <h4>La qualifica di Utente</h4>
                    L'utilizzo di qualsiasi servizio offerto dal Sito (di seguito: “Servizio” o “Servizi”) attribuisce all’utilizzatore la qualifica di Utente (di seguito: “Utente” o “Utenti”). Solo per l'uso di alcuni Servizi può essere richiesta la registrazione degli Utenti nel modo richiesto dal Servizio stesso. I Servizi di pubblicazione offerti attraverso il Sito sono generalmente gratuiti; solo l'utilizzo di alcuni di questi può essere soggetto a opzioni a pagamento. Per opzioni a pagamento (di seguito: “Opzioni a Pagamento”) si intende l’acquisto da parte dell’Utente privato di funzioni aggiuntive facoltativamente selezionabili. Ogni questione relativa alla fatturazione dovrà essere gestita dall’Utente contattando direttamente <a href="https://farmacontatto.it/contatti/">l'amministrazione di Farmacontatto S.a.s.</a>
                    <h4>Tipologie di Utenti</h4>
                    Relativamente all'utilizzo del Sito, gli Utenti si distinguono in:
                    <ul>
                        <li>Utenti Semplici - sono privati che utilizzano il Sito con l’obiettivo di ricercare farmacie in vendita e/o proporre una farmacia in vendita e possono accedere ai servizi di ricerca in maniera anonima.</li>
                        <li>Utenti Professionali - sono agenti immobiliari, commercialisti di farmacie altri professionisti e società operanti nel settore delle farmacie che utilizzano il Sito per fornire servizi agli Utenti Semplici loro clienti.</li>
                    </ul>
                    Gli Utenti Professionali possono accedere all’area riservata a loro destinata solo dopo aver stipulato con Farmacontatto un contratto di servizio (di seguito “Contratto di Servizio”). La pubblicazione degli annunci è regolamentata per Utenti Semplici e Utenti Professionali al paragrafo Pubblicazione degli annunci.
                    <h4>Usi Consentiti e Vietati</h4>
                    L’utilizzo dei Servizi è limitato ai maggiori di 18 anni.
                    È vietato svolgere, direttamente o indirettamente, qualsiasi forma di sfruttamento commerciale dei Servizi attraverso l’accesso, l'uso, il trasferimento di informazioni contenute nel Sito. La violazione di questo divieto sarà perseguita a norma di legge. L'Utente riconosce e accetta che l'uso del contenuto e/o dei Servizi offerti dal Sito è a proprio esclusivo rischio e sotto la sua responsabilità. L'Utente si impegna a utilizzare il Sito e tutti i contenuti e Servizi in conformità alla legge e secondo quanto previsto in queste Condizioni. Allo stesso modo si impegna inoltre a fare un uso appropriato dei Servizi e/o dei contenuti del Sito e a non impiegarli per attività illecite, contrarie alla buona fede o delittuose, violare i diritti di terzi e/o regolamenti che violano la proprietà intellettuale, o di qualsiasi altra norma dell’ordinamento giuridico. In particolare, l'Utente si impegna a non trasmettere, introdurre, distribuire e rendere disponibili a terzi, qualsiasi materiale e informazione (dati, messaggi, immagini, file audio e immagini, fotografie, software, ecc.) che siano in contrasto con la legge, la morale, l'ordine pubblico e le Condizioni. È proibito l'uso del Sito per scopi diversi da quelli previsti da Farmacontatto. Inoltre, Farmacontatto si riserva il diritto di non concedere l'accesso a servizi e contenuti del Sito ad insindacabile giudizio della sua politica commerciale. Allo stesso modo si riserva il diritto di revocare l'accesso alle sue pagine senza preavviso a qualsiasi Utente che violi le disposizioni delle presenti informazioni legali.
                    <h4>Proprietà Intellettuale</h4>
                    Farmacontatto è la legittima proprietaria del Sito, delle pagine in esso contenute, delle informazioni o elementi contenuti nei testi, documenti, fotografie, disegni, grafica, database, software, loghi, marchi, nomi commerciali o altri segni protetti da diritti di proprietà intellettuale o industriale. L'Utente non può riprodurre, trasformare, modificare, decodificare, distribuire, affittare, mettere a disposizione di chiunque o consentire l'accesso attraverso ogni forma di comunicazione al pubblico di qualsiasi degli elementi menzionati al punto precedente senza espressa autorizzazione scritta.
                    <h4>Garanzie e Responsabilità</h4>
                    Farmacontatto non garantisce la continuità di accesso né la corretta visualizzazione, il download o l'utilizzo degli elementi e delle informazioni contenute nelle pagine del Sito, che possono essere impediti, ostacolati o interrotti da fattori o circostanze al di fuori del suo controllo. Farmacontatto non è responsabile per le informazioni e altri contenuti integrati in zone o siti di terzi accessibili dal Sito. Farmacontatto non si assume alcuna responsabilità per quanto riguarda le informazioni, i contenuti, i prodotti e servizi offerti o forniti attraverso il Sito da terze persone o entità. Né Farmacontatto, né i fornitori terzi di informazioni assumono alcuna responsabilità per eventuali danni, perdite, reclami o spese derivanti da: interferenze, interruzioni, errori, omissioni, guasti, ritardi, blocchi o sconnessioni nel funzionamento del sistema causati da carenze, errori e/o sovraccarichi sulle linee di comunicazione, o per qualsiasi altra causa al di fuori del controllo di Farmacontatto, l'impiego di qualsiasi programma illegale e/o maligno e con ogni tipo di media, come ad esempio virus o quant’altro, l'uso improprio o scorretto dei siti di Farmacontatto, la sicurezza di navigazione o errori causati dal funzionamento del browser, del software in generale e/o dall’hardware.
                    <h4>Contrattazione con Terze Parti attraverso il Sito</h4>
                    L'Utente riconosce e accetta che qualsiasi relazione contrattuale o extracontrattuale formalizzata con Inserzionisti o soggetti terzi contattati attraverso il Sito, saranno effettuati solo ed esclusivamente tra l'Utente e l'Inserzionista e/o soggetto terzo e che Farmacontatto non abbia alcuna responsabilità per eventuali danni di qualsiasi natura in relazione con i negoziati, le conversazioni e/o le obbligazioni contrattuali con gli Inserzionisti o terze persone fisiche o giuridiche contattati attraverso il Sito.
                    <h4>Consultazione degli Annunci</h4>
                    Farmacontatto declina ogni responsabilità in merito alla qualità dei risultati offerti nelle ricerca delle informazioni all’interno del Sito, al contenuto, all'accuratezza, alla veridicità e alla rispondenza alle regole contenute nelle presenti Condizioni. L'Utente ha la possibilità segnalare eventuali annunci errati, illegali, truffaldini, sospetti o che non rispettino le regole previste da queste Condizioni inviando una segnalazione utilizzando la pagina dei <a href="https://farmacontatto.it/contatti/">contatti</a>. Gli annunci reperibili su Sito vengono richiesti dagli Utenti e la loro successiva pubblicazione è curata da Farmacontatto ma ciò non implica, per questo, alcuna forma di controllo o garanzia da parte della stessa in merito alla veridicità del contenuto e/o alla affidabilità delle informazioni in essi contenute. Farmacontatto non è in alcun modo responsabile della veridicità, correttezza, completezza, nonché della normativa in materia di privacy e/o di alcun altro aspetto dei suddetti annunci.
                    <h4>Pubblicazione degli Annunci</h4>
                    La pubblicazione degli annunci è regolamentata dalle presenti Condizioni Generali per gli Utenti Semplici e dal Contratto di Servizio per gli Utenti Professionali. Gli Utenti Semplici hanno facoltà di richiedere l’inserimento di un annuncio di livello “Base” in modalità gratuita o di altri livelli di annuncio a pagamento. E’ espressamente vietata agli Utenti Professionali ogni richiesta di pubblicazione di annunci come Utenti Semplici in quanto é per loro prevista la sottoscrizione un Contratto di Servizio per chiedere di pubblicare annunci. Farmacontatto si riserva il diritto di cancellare annunci in via permanente o anche temporanea, a sua sola ed esclusiva discrezione.
                    <h4>Privacy e Protezione dei Dati Personali</h4>
                    I dati personali degli Utenti sono trattati secondo le norme vigenti e in base a quanto riportato nell'Informativa sulla Privacy e Protezione dei Dati Personali.
                    <ul>
                        <li>Sicurezza - Farmacontatto adotta misure di sicurezza ritenute idonee a garantire la riservatezza e la segretezza dei dati personali, a norma di legge e descritte nell’Informativa sulla Privacy e Protezione dei Dati Personali.</li>
                        <li>Cookie - Alcune pagine di Sito dispongono di cookies non contenenti dati personali con la funzione di personalizzare e facilitare la navigazione. L'Utente può dare il consenso o revocare lo stesso in qualunque momento e/o attivare le impostazioni del proprio browser per rifiutare l'installazione di cookies. Per ulteriori informazioni, si prega di consultare la pagina Uso dei Cookie.</li>
                    </ul>
                    <h4>Risoluzione</h4>
                    Farmacontatto si riserva il diritto di negare l'accesso all’area riservata del Sito a sua sola ed insindacabile discrezione. Gli Utenti Professionali saranno informati di tale decisione e non potranno successivamente tentare di accedere al sito in forma anonima, sotto diverso nome o a mezzo di altri Utenti. L'Utente risponderà dei danni di qualsiasi tipo che Farmacontatto potrebbe subire, direttamente o indirettamente, come conseguenza di qualsiasi violazione degli obblighi derivati dalle Condizioni di Utilizzo relativamente all’uso del Sito e manterrà la stessa indenne da qualsiasi sanzione, reclamo o citazione a giudizio da parte di terzi, compresi Enti pubblici, contro di essa, i suoi dipendenti o collaboratori, a seguito della violazione dei diritti di terzi da parte dell'Utente che dovesse utilizzare il Sito o i servizi ad esso connessi in modo difforme a quanto previsto dalle presenti Condizioni.
                    <h4>Modifiche</h4>
                    Farmacontatto si riserva il diritto, senza preavviso alcuno e a suo insindacabile giudizio, di apportare le modifiche che ritiene opportune, potendo modificare, cancellare e aggiungere nuovi contenuti e/o servizi ed anche, eventualmente, nel modo in cui gli stessi vengono presentati.
                    <h4>Durata e Termine</h4>
                    I servizi comprendenti Opzioni a Pagamento hanno durata vincolata al periodo indicato all’interno del Sito. I servizi gratuiti e/o i contenuti del Sito potranno essere erogati per un periodo di tempo indeterminato o per il tempo indicato all’interno del Sito. In ogni caso, Farmacontatto ha il potere di chiudere, sospendere o interrompere unilateralmente in qualsiasi momento e senza preavviso, la prestazione del Servizio e del Sito.
                    <h4>Legge applicabile e Giurisdizione</h4>
                    Salvo quanto disposto da norme di legge non derogabili, il Tribunale di Milano sarà competente in via esclusiva a dirimere ogni controversia riguardante le presenti Condizioni ed i rapporti dalle stesse regolati.
                </div>

            </div>
            <br></br>
        </>
    )
}