import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ChisiamoTesta } from './ChisiamoTesta';
import { ChisiamoTesta_md } from './ChisiamoTesta_md';
import { ChisiamoTesta_sm } from './ChisiamoTesta_sm';

export const Chisiamo_Head = () => {
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Inserzioni ricerca farmacie in vendita e annunci farmacie in vendita - Pharmabroker</title>
          <meta name="description" content='Trova farmacie da acquistare su Pharmabroker.it ⭐ Inserzioni per la ricerca e la vendita di farmacie. ▷ Scopri chi siamo e le migliori opportunità.' />
        </Helmet>
      </HelmetProvider>

      {/* Visibile su schermi extra grandi */}
      <div className="container-fluid d-none d-xl-block" id='Pharmabroker'>
        <ChisiamoTesta />
      </div>

      {/* Visibile su schermi grandi */}
      <div className="container-fluid d-none d-lg-block d-xl-none" id='Pharmabroker'>
        <ChisiamoTesta />
      </div>

      {/* Visibile su schermi medi */}
      <div className="container-fluid d-none d-md-block d-lg-none" id='Pharmabroker'>
        <ChisiamoTesta_md />
      </div>

      {/* Visibile su schermi piccoli */}
      <div className="container-fluid d-none d-sm-block d-md-none" id='Pharmabroker'>
        <ChisiamoTesta_sm />
      </div>

      {/* Visibile su schermi extra piccoli */}
      <div className="container-fluid d-xs-block d-sm-none pt-5" id='Pharmabroker'>
        <ChisiamoTesta_sm />
      </div>
    </>
  )
}

export default Chisiamo_Head;